import { initContract } from "@ts-rest/core";

import { aiContract } from "./ai";
import { billingContract } from "./billing";
import { calendarContract } from "./calendar";
import { clientsContract } from "./clients";
import { indirectsContract } from "./indirects";
import { programContract } from "./programs";
export * from "./calendar";
export * from "./programs";

const c = initContract();

export const contract = c.router({
  programs: programContract,
  ai: aiContract,
  calendar: calendarContract,
  indirects: indirectsContract,
  clients: clientsContract,
  billing: billingContract,
});
