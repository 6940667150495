import { initContract } from "@ts-rest/core";
import { z } from "zod";

const c = initContract();

export const billingContract = c.router({
  unfinalizeAppointments: {
    method: "POST",
    path: "/billing/unfinalize-appointments",
    body: z.object({
      appointmentIds: z.array(z.string()),
      clinicId: z.string(),
    }),
    responses: {
      200: z.null(),
      422: z.object({
        message: z.string(),
        appointmentId: z.string(),
      }),
    },
  },
});
