import { getAuth } from "firebase/auth";

import app from "./firebase";

const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

export const endpoint = (uri: string, method: string) => {
  const configuredEndpoint = async (body: any) => {
    const authToken = await getAuth(app).currentUser?.getIdToken();
    let response;

    switch (method) {
      case "get":
        response = await fetch(`${BACKEND_BASE_URL}${uri}/?${new URLSearchParams(body)}`, {
          method,
          mode: "cors",
          headers: {
            "content-type": "application/json",
            authorization: authToken || "",
          },
        });
        break;
      case "post":
      case "put":
        response = await fetch(`${BACKEND_BASE_URL}${uri}`, {
          method,
          mode: "cors",
          body: JSON.stringify(body),
          headers: {
            "content-type": "application/json",
            authorization: authToken || "",
          },
        });
        break;
      default:
        throw new Error(`Request method ${method} not supported`);
    }

    const result = await response.json();

    return result;
  };

  return configuredEndpoint;
};
