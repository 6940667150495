import { initContract } from "@ts-rest/core";
import { z } from "zod";

import { IClient } from "../types";

const c = initContract();
export const clientsContract = c.router({
  getByClinic: {
    method: "GET",
    path: "/clients/get-all-by-user-and-clinic",
    query: z.object({
      // query params are all strings
      activeOnly: z
        .enum(["yes", "no"])
        .optional()
        .transform((val) => val === "yes"),
      clinicId: z.string(),
      // Unused, but kept for outdated frontends
      userId: z.any(),
    }),
    responses: {
      // TODO: Convert to zod schema
      200: c.type<IClient[]>(),
      400: z.object({
        message: z.string(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
  sendInsuranceConfirmationReminders: {
    method: "POST",
    path: "/clients/send-insurance-confirmation-reminders",
    body: z.object({
      clientIds: z.array(z.string()),
      // This is needed to enforce permissions
      clinicId: z.string(),
    }),
    responses: {
      202: z.object({
        success: z.boolean(),
      }),
      403: z.object({
        message: z.string(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
});
