import { IUser, ProviderType } from "./general";

// Predefined event types
export enum AnalyticsEvent {
  PAGE_LOAD_TIME = "Page Load Time",
  ACTIVE_SESSION_STARTED = "Active Session Started",
  ACTIVE_SESSION_LOADED = "Active Session Loaded",
  ACTIVE_SESSION_ENDED = "Active Session Ended",
  MEASUREMENT_CREATED = "Measurement Created",
  MEASUREMENT_UPDATED = "Measurement Updated",
  MEASUREMENT_DELETED = "Measurement Deleted",
  PROGRAM_TEMPLATE_UPDATED = "Program Template Updated",
  PROGRAM_TEMPLATE_CREATED = "Program Template Created",
  PROGRAM_TEMPLATE_DELETED = "Program Template Deleted",
  NOTE_CREATED = "Note Created",
  NOTE_UPDATED = "Note Updated",
  NOTE_DELETED = "Note Deleted",
  NARRATIVE_GENERATED = "Narrative Generated",
  TARGET_CREATED = "Target Created",
  TARGET_UPDATED = "Target Updated",
  TARGET_DELETED = "Target Deleted",
}

export interface BaseEventProperties {
  user_id: string;
  provider_type: ProviderType;
  clinic_id: string;
  clinic_name: string;
  clinic_display_name: string;
}

export interface TrackEventProperties {
  eventName: AnalyticsEvent;
  user: Pick<IUser, "id" | "providerType" | "clinicId">;
  additionalProperties?: Record<string, any>;
}
