import { Switch } from "react-router-dom";

import { IntakeQuestionnaire } from "../components/ClientOnboardingFlow/IntakeQuestionnaire";
import { Preapproval } from "../components/ClientOnboardingFlow/Preapproval";
import { PrivateRoute } from "../components/PrivateRoute";
import { ClientFileFlow } from "./ClientFileFlow";
import { ConfirmInsurancePage } from "./ConfirmInsurancePage";
import { InsuranceConfirmedPage } from "./InsuranceConfirmedPage";
import { SetPassword } from "./SetPassword";

export const HomeRoutes = () => (
  <Switch>
    <PrivateRoute path="/preapproval" exact>
      <Preapproval />
    </PrivateRoute>
    <PrivateRoute path="/intake-questionnaire" exact>
      <IntakeQuestionnaire />
    </PrivateRoute>
    <PrivateRoute path="/set-password" exact>
      <SetPassword />
    </PrivateRoute>
    <PrivateRoute path="/confirm-insurance" exact>
      <ConfirmInsurancePage />
    </PrivateRoute>
    <PrivateRoute path="/insurance-confirmed" exact>
      <InsuranceConfirmedPage />
    </PrivateRoute>
    {/* Default route */}
    <PrivateRoute path={["/", "/inquire-complete"]}>
      <ClientFileFlow />
    </PrivateRoute>
  </Switch>
);
