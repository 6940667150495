import {
  deleteObject,
  getBytes,
  getStorage,
  ref,
  StorageReference,
  uploadBytes,
} from "firebase/storage";

import app from "../services/firebase";

/**
 * This function is a workaround for the lack of
 * a move function in the Firebase Storage SDK.
 *
 * @param fileUrl URL of the object to be moved
 * @param storageLocation location to move the object to
 * @returns a reference to the moved object
 */
export const moveObject = async (
  fileUrl: string,
  storageLocation: string
): Promise<StorageReference> => {
  const storage = getStorage(app);

  try {
    const objectRef = ref(storage, fileUrl);
    const data = await getBytes(objectRef);
    const results = await uploadBytes(ref(storage, storageLocation), data);
    await deleteObject(objectRef);

    return results.ref;
  } catch (error) {
    console.error(error);
    throw new Error("Failed to move the object", { cause: error });
  }
};
