import { useLocation } from "react-router-dom";

/**
 * TODO: When we upgrade to React Router v6, we can use the built-in `useSearchParams` hook.
 */
export const useQueryParams = () => {
  const { search } = useLocation();

  return new URLSearchParams(search);
};
