import { LogoutOutlined } from "@ant-design/icons";
import { useResponsive } from "@finni-health/ui";
import { Avatar, Button, Layout, List, Popover, Row, Typography } from "antd";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";

import { useLogout } from "../hooks/useLogout";
import { AuthContext } from "./AuthProvider";

const { Text, Link: LinkText } = Typography;

export const Header: React.FC = () => {
  const { guardian, clinic } = useContext(AuthContext);
  const logout = useLogout();

  const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { isMobile } = useResponsive();

  useEffect(() => {
    setIsLoading(true);
    if (!_.isEmpty(guardian) && !_.isEmpty(clinic)) {
      setIsLoading(false);
    }
  }, [guardian, clinic]);

  const profilePopover = () => {
    return (
      <div style={{ width: 100 }}>
        <List style={{ marginTop: -12, marginBottom: -12 }}>
          <List.Item>
            <LinkText type="danger" onClick={logout}>
              <LogoutOutlined /> Logout
            </LinkText>
          </List.Item>
        </List>
      </div>
    );
  };

  return (
    <Layout.Header
      style={{
        position: "sticky",
        top: 0,
        zIndex: 1,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        background: "#fff",
        ...(isMobile && { padding: "0px 10px !important" }),
      }}
    >
      <Row justify="space-between" style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img src="/logo.svg" height={20} />
        </div>
        <div>
          {!isLoading && (
            <Popover
              placement="bottomRight"
              content={profilePopover()}
              trigger="click"
              visible={isPopoverVisible}
              onVisibleChange={setIsPopoverVisible}
            >
              <Button type="text">
                <Avatar
                  size="small"
                  style={{
                    backgroundColor: "#C0D3BF",
                    color: "#3B553A",
                  }}
                >
                  {!_.isEmpty(guardian) && guardian.firstName[0] + guardian.lastName[0]}
                </Avatar>
                {!isMobile && (
                  <Text
                    style={{
                      fontSize: 12,
                      marginLeft: 5,
                    }}
                  >
                    {`${guardian.firstName} ${guardian.lastName}`}
                  </Text>
                )}
              </Button>
            </Popover>
          )}
        </div>
      </Row>
    </Layout.Header>
  );
};
