import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { isDefined } from "@finni-health/shared";
import { useResponsive } from "@finni-health/ui";
import { Button, Col, Divider, Form, Input, message, Row, Space } from "antd";
import { getAuth } from "firebase/auth";
import { isNil } from "lodash";
import React, { useState } from "react";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";

import { signIn } from "../helpers/sign-in";
import app from "../services/firebase";

interface ILocationState {
  from?: Location;
}

interface ILoginFormValues {
  email: string;
  password: string;
}

export const Login: React.FC = () => {
  const state = useLocation().state as ILocationState;
  const history = useHistory();
  const isLoggedIn = Boolean(getAuth(app).currentUser);
  const { isMobile } = useResponsive();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // We want to prefill email if it's in the query params
  const searchParams = new URLSearchParams(state?.from?.search || {});
  const prefillEmail: string | null = searchParams.get("email");

  const handleSignIn = async (values: ILoginFormValues) => {
    setIsLoading(true);

    const { email, password } = values;
    await signIn(
      email,
      password,
      () => {
        history.push(state?.from || "/");
      },
      () => {
        void message.error("Invalid email or password");
      }
    );

    setIsLoading(false);
  };

  return isLoggedIn ? (
    <Redirect to="/" />
  ) : (
    <Row style={{ height: "100vh", overflow: "hidden" }} justify="center" align="middle">
      <Col span={isMobile ? 24 : 12}>
        <Row style={{ height: "100vh" }} justify="center" align="middle">
          <Col span={isMobile ? 20 : 12}>
            <a
              href="https://www.finnihealth.com"
              style={{
                display: "flex",
                justifyContent: isMobile ? "center" : "start",
                marginBottom: 32,
              }}
            >
              <img src="/logo.svg" height={40} />
            </a>
            <Form
              name="basic"
              onFinish={handleSignIn}
              initialValues={{ email: prefillEmail ?? "" }}
            >
              <Space direction="vertical" style={{ width: "100%" }}>
                <Form.Item name="email" rules={[{ required: true, message: "Email is required" }]}>
                  <Input
                    prefix={<UserOutlined />}
                    placeholder="Email Address"
                    disabled={isDefined(prefillEmail)}
                    autoFocus={isNil(prefillEmail)}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[{ required: true, message: "Password is required" }]}
                >
                  <Input.Password
                    prefix={<LockOutlined />}
                    placeholder="Password"
                    autoFocus={isDefined(prefillEmail)}
                  />
                </Form.Item>

                <Row justify="space-between" align="middle">
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Button type="primary" shape="round" htmlType="submit" loading={isLoading}>
                      Log in
                    </Button>
                  </Form.Item>
                  <Link to="/forgot-password" style={{ fontWeight: 500 }}>
                    Forgot Password?
                  </Link>
                </Row>
              </Space>
            </Form>

            <Divider style={{ fontSize: 14 }}>or</Divider>

            <Row justify="center">
              <Link
                to="/inquire"
                style={{
                  width: "50%",
                  minWidth: 125,
                }}
              >
                <Button shape="round" style={{ width: "100%" }}>
                  Sign Up
                </Button>
              </Link>
            </Row>
          </Col>
        </Row>
      </Col>
      {!isMobile && (
        <Col span={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginBottom: "3em",
              height: "100vh",
            }}
          >
            <img src="/kiddo.png" style={{ maxWidth: "50vw", objectFit: "cover" }} />
          </div>
        </Col>
      )}
    </Row>
  );
};
