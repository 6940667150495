import { Form, FormItemProps } from "antd";
import { PropsWithChildren } from "react";

export const NewInsuranceFormItem = ({ children, ...props }: PropsWithChildren<FormItemProps>) => (
  <Form.Item
    style={{ width: "100%" }}
    labelCol={{ xs: 14, sm: 12, lg: 11 }}
    rules={[{ required: true, message: "This field is required" }]}
    {...props}
  >
    {children}
  </Form.Item>
);
