import { ResponsiveProvider } from "@finni-health/ui";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { AuthProvider } from "./components/AuthProvider";
import { PrivateRoute } from "./components/PrivateRoute";
import { ForgotPassword } from "./pages/ForgotPassword";
import { Home } from "./pages/Home";
import { InquiryFlow } from "./pages/InquiryFlow";
import { Loading } from "./pages/Loading";
import { Login } from "./pages/Login";
import { Schedule } from "./pages/Schedule";
import { SecretSignUp } from "./pages/SecretSignUp";
import app from "./services/firebase";

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID || "bogus");

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
      networkMode: "offlineFirst",
    },
    mutations: {
      networkMode: "offlineFirst",
    },
  },
});

function App() {
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  useEffect(() => {
    const auth = getAuth(app);
    const unsubscribe = onAuthStateChanged(auth, () => {
      setIsInitialized(true);
    });

    // Cleanup auth listener when component unmounts
    return () => unsubscribe();
  }, []);

  return isInitialized ? (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ResponsiveProvider>
          <Router>
            <Switch>
              <Route path="/:clinicName/signup" component={SecretSignUp} />
              <Route path="/inquire" component={InquiryFlow} />
              <Route path="/login" component={Login} />
              <Route path="/forgot-password" component={ForgotPassword} />
              <Route path="/schedule/:clientFileHash" component={Schedule} />
              <PrivateRoute path="/">
                <Home />
              </PrivateRoute>
            </Switch>
          </Router>
        </ResponsiveProvider>
      </AuthProvider>
    </QueryClientProvider>
  ) : (
    <Loading />
  );
}

export default App;
