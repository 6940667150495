import { PrequalifiedStatus } from "../types";
import { ActiveStatus } from "../types";
import { IntakeStatus } from "../types";

/*
 * Function for determining if a given IntakeStatus is Active
 */
export function isActiveStatus(status: IntakeStatus): boolean {
  return Object.values(ActiveStatus).includes(status as any);
}

/*
 * Function for determining if a given IntakeStatus is Pre-qualified
 */
export const isPrequalifiedStatus = (status: IntakeStatus) => {
  return Object.keys(PrequalifiedStatus).includes(status);
};
