import { message } from "antd";
import { getAuth, signOut } from "firebase/auth";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";

import app from "../services/firebase";

/**
 * Hook to log the user out.
 */
export const useLogout = () => {
  const history = useHistory();

  const logout = useCallback(async () => {
    try {
      await signOut(getAuth(app));
      void message.info("Successfully logged out");
      history.push("/login");
    } catch (error) {
      console.error("Failed to log out", error);
      void message.error("Failed to log out");
    }
  }, [app, history]);

  return logout;
};
