import { CheckCircleTwoTone } from "@ant-design/icons";
import { Button, Card, Row, Space, Typography } from "antd";
import { useHistory } from "react-router-dom";

const { Text } = Typography;

export const InsuranceConfirmedPage = () => {
  const history = useHistory();

  return (
    <Card>
      <Row style={{ flexDirection: "column", rowGap: 15 }}>
        <Space>
          <CheckCircleTwoTone
            twoToneColor="#52c41a"
            style={{ fontSize: 18, verticalAlign: "text-bottom" }}
          />
          <Text>Thanks for confirming your insurance info!</Text>
        </Space>
        <Button shape="round" type="primary" onClick={() => history.push("/")}>
          Go to homepage
        </Button>
      </Row>
    </Card>
  );
};
