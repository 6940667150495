import { EditOutlined, EyeOutlined, InboxOutlined } from "@ant-design/icons";
import { Button, Card, Image, message, Row, Space, Spin, Typography } from "antd";
import Upload from "antd/lib/upload";
import Dragger from "antd/lib/upload/Dragger";
import { UploadListType } from "antd/lib/upload/interface";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { isEmpty } from "lodash";
import { useContext, useState } from "react";

import app from "../../services/firebase";
import { AuthContext } from "../AuthProvider";

const { Text } = Typography;
const DEFAULT_MAX_FILE_SIZE = 10_000_000; // 10000000 Bytes or 10MB

interface InsuranceFileUploadProps {
  label: string;
  name: string;
  storagePath: string;
  initialFileUrl?: string;
  isEditable?: boolean;
  // To be used for form validation
  onChange?: (value: any) => void;
}

export const InsuranceFileUpload = ({
  label,
  name,
  storagePath,
  initialFileUrl,
  isEditable,
  onChange,
}: InsuranceFileUploadProps) => {
  const { clientFile } = useContext(AuthContext);
  const [fileUrl, setFileUrl] = useState<string | undefined>(initialFileUrl);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [fileList, setFileList] = useState<any>([]);

  const beforeUpload = async (file: File) => {
    if (file.size > DEFAULT_MAX_FILE_SIZE) {
      void message.error("Your file is too large. Please select a file under 10MB");
      setFileList([]);
      return false;
    }

    setFileList([file]);
    setIsUploading(true);

    try {
      const storage = getStorage(app);
      const clientFileInsuranceLocation = `clientFiles/${clientFile.id}/insurance/${storagePath}`;
      const results = await uploadBytes(ref(storage, clientFileInsuranceLocation), file);
      const tempURL = await getDownloadURL(results.ref);
      setFileUrl(tempURL);

      void message.success("Successfully Uploaded");

      if (onChange) {
        onChange(tempURL);
      }

      // TODO: Analytics
    } catch (error) {
      console.error(error);
      void message.error("Failed to upload file");
    } finally {
      setIsUploading(false);
    }

    // Prevent remote upload
    return false;
  };

  const uploadProps = {
    name,
    fileList,
    beforeUpload,
    // TODO: Make these props configurable
    accept: "image/apng, image/avif, image/gif, image/jpeg, image/png, image/svg+xml, image/webp",
    multiple: false,
    showUploadList: false,
    listType: "picture" as UploadListType,
  };

  return (
    <>
      {isEmpty(fileUrl) ? (
        <Space style={{ display: "block", width: "100%" }}>
          <Dragger {...uploadProps} style={{ margin: "5px auto" }}>
            {isUploading ? (
              <Spin />
            ) : (
              <Row justify="center" align="middle" style={{ gap: 5 }}>
                <Text className="ant-upload-drag-icon">
                  <InboxOutlined />
                </Text>
                <Text>{label}</Text>
              </Row>
            )}
          </Dragger>
        </Space>
      ) : (
        <Card
          style={{
            margin: "5px 0",
            width: "100%",
          }}
          bodyStyle={{ padding: "10px" }}
        >
          <Row justify="space-between">
            <Row justify="center" align="middle" style={{ gap: 10 }}>
              <Image src={fileUrl} width={35} height={35} preview={{ mask: <EyeOutlined /> }} />
              <Text>{label}</Text>
            </Row>
            <Upload {...uploadProps}>
              {isEditable && <Button size="middle" type="text" icon={<EditOutlined />} />}
            </Upload>
          </Row>
        </Card>
      )}
    </>
  );
};
