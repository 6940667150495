import { IPayer, isDefined } from "@finni-health/shared";
import { useQuery } from "@tanstack/react-query";
import { Select } from "antd";
import { useContext } from "react";

import * as FirestoreService from "../../../services/firestore";
import { AuthContext } from "../../AuthProvider";

interface InsurancePayerSelectProps {
  onChange: (payerId: string) => void;
}

export const InsurancePayerSelect = ({ onChange }: InsurancePayerSelectProps) => {
  const { client } = useContext(AuthContext);

  const { data: payers = [] } = useQuery({
    queryKey: ["getAllPayersForState", client?.address?.state],
    queryFn: () => {
      return FirestoreService.getAllPayersForState(client?.address?.state);
    },
    enabled: isDefined(client?.address?.state),
  });

  return (
    <Select
      placeholder="Select a payer"
      showSearch
      filterOption={(input: string, option: any) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      onChange={onChange}
    >
      {payers.map((payer: IPayer) => (
        <Select.Option key={payer.payerId}>{`${payer.name} (${payer.state})`}</Select.Option>
      ))}
    </Select>
  );
};
