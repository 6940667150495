import { ArrowLeftOutlined } from "@ant-design/icons";
import { IClinic, USStateCode } from "@finni-health/shared";
import { COLORS } from "@finni-health/ui";
import { useResponsive } from "@finni-health/ui";
import { Button, Col, Row } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { ContactForm } from "../components/InquiryFlow/ContactForm";
import { EnterDiagnoses } from "../components/InquiryFlow/EnterDiagnoses";
import { EnterIsGuardian } from "../components/InquiryFlow/EnterIsGuardian";
import { EnterLanguagePreference } from "../components/InquiryFlow/EnterLanguagePreference";
import { EnterZipCode } from "../components/InquiryFlow/EnterZipCode";
import { Submitted } from "../components/InquiryFlow/Submitted";
import { UnsupportedDiagnoses } from "../components/InquiryFlow/UnsupportedDiagnoses";
import { UnsupportedGuardianStatus } from "../components/InquiryFlow/UnsupportedGuardianStatus";
import { UnsupportedZipCode } from "../components/InquiryFlow/UnsupportedZipCode";
import { InquiryStates } from "../consts";
import { useQueryParams } from "../hooks/useQueryParams";
import * as FirestoreService from "../services/firestore";
import { Loading } from "./Loading";

export interface IInquiryForm {
  clinicId: string;
  zipCode: string;
  isGuardian: boolean;
  isDiagnosed: boolean;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  intakeStatusNotes: string;
}

export const InquiryFlow: React.FC = () => {
  const history = useHistory();
  const queryParams = useQueryParams();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [inquiryStatus, setInquiryStatus] = useState<InquiryStates>(InquiryStates.ENTER_ZIP_CODE);
  const [inquiry, setInquiry] = useState<IInquiryForm>({
    clinicId: "",
    zipCode: "",
    isGuardian: false,
    isDiagnosed: false,
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    intakeStatusNotes: "",
  });
  const [clinic, setClinic] = useState<IClinic>();
  const [shouldAskLanguagePref, setShouldAskLanguagePref] = useState<boolean>(true);
  const { isMobile } = useResponsive();

  useEffect(() => {
    handleQueryZip().catch(() => {});
  }, [queryParams]);

  const updateInquiry = (source: any) => {
    const merged = _.merge(_.cloneDeep(inquiry), source);
    setInquiry(merged);
  };

  const handleQueryZip = async () => {
    setIsLoading(true);
    const queryZip = queryParams.get("zipCode");
    if (queryZip) {
      let clinic;
      try {
        clinic = await FirestoreService.getClinicByZipCode(queryZip);
      } catch (err) {
        console.log(`Error getting clinic: ${err}`);
      }
      if (clinic !== undefined) {
        updateInquiry({ clinicId: clinic.id, zipCode: queryZip });
        setClinic(clinic);
        setInquiryStatus(InquiryStates.ENTER_GUARDIAN_STATUS);
      } else {
        updateInquiry({ zipCode: queryZip });
        setInquiryStatus(InquiryStates.UNSUPPORTED_ZIP_CODE);
      }
      history.push("/inquire");
    }
    setIsLoading(false);
  };

  const getIntakeFormComponent = () => {
    switch (inquiryStatus) {
      case InquiryStates.ENTER_ZIP_CODE:
        return (
          <EnterZipCode
            inquiry={inquiry}
            setInquiryStatus={setInquiryStatus}
            setClinic={setClinic}
            updateInquiry={updateInquiry}
          />
        );
      case InquiryStates.UNSUPPORTED_ZIP_CODE:
        return (
          <UnsupportedZipCode
            inquiry={inquiry}
            setInquiryStatus={setInquiryStatus}
            updateInquiry={updateInquiry}
          />
        );
      case InquiryStates.ENTER_GUARDIAN_STATUS:
        if (
          clinic &&
          !_.isEmpty(clinic) &&
          clinic.address.state === USStateCode.NM &&
          shouldAskLanguagePref
        ) {
          return (
            <EnterLanguagePreference
              setShouldAskLanguagePref={setShouldAskLanguagePref}
              updateInquiry={updateInquiry}
            />
          );
        } else {
          return (
            <EnterIsGuardian setInquiryStatus={setInquiryStatus} updateInquiry={updateInquiry} />
          );
        }
      case InquiryStates.UNSUPPORTED_GUARDIAN_STATUS:
        return (
          <UnsupportedGuardianStatus
            inquiry={inquiry}
            setInquiryStatus={setInquiryStatus}
            updateInquiry={updateInquiry}
          />
        );
      case InquiryStates.ENTER_DIAGNOSES:
        return <EnterDiagnoses setInquiryStatus={setInquiryStatus} updateInquiry={updateInquiry} />;
      case InquiryStates.UNSUPPORTED_DIAGNOSES:
        return (
          <UnsupportedDiagnoses
            inquiry={inquiry}
            setInquiryStatus={setInquiryStatus}
            updateInquiry={updateInquiry}
          />
        );
      case InquiryStates.CONTACT_FORM:
        return <ContactForm inquiry={inquiry} />;
      case InquiryStates.SUBMITTED:
        return <Submitted />;
    }
  };

  return (
    <Row style={{ height: "100%" }}>
      <Col span={24}>
        <Row
          style={{
            width: "100%",
            marginTop: 30,
            paddingBottom: 30,
            borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
          }}
          justify="center"
          align="middle"
        >
          {inquiryStatus > 0 && inquiryStatus <= 3 && (
            <>
              <Button
                onClick={() => {
                  setInquiryStatus(inquiryStatus - 1);
                }}
                style={{ height: 40, position: "absolute", left: 20 }}
                type="text"
              >
                <ArrowLeftOutlined />
              </Button>
              <Button
                type="link"
                href="/login"
                shape="round"
                style={{
                  position: "absolute",
                  right: 20,
                  top: 30,
                  height: 44,
                }}
              >
                Log In
              </Button>
            </>
          )}
          <Col>
            <a
              href="https://www.finnihealth.com"
              style={{
                display: "flex",
                justifyContent: isMobile ? "center" : "start",
                width: "100%",
              }}
            >
              <img src="/logo.svg" height={25} />
            </a>
          </Col>
        </Row>
        <div
          style={{
            background: COLORS.PRIMARY,
            marginBottom: isMobile ? 25 : 100,
            height: 3,
            transition: "all 0.4s",
            width:
              inquiryStatus === InquiryStates.ENTER_ZIP_CODE
                ? "10%"
                : inquiryStatus === InquiryStates.ENTER_GUARDIAN_STATUS
                ? "40%"
                : inquiryStatus === InquiryStates.ENTER_DIAGNOSES
                ? "75%"
                : inquiryStatus === InquiryStates.CONTACT_FORM
                ? "100%"
                : 0,
          }}
        />
        <Row
          style={{
            width: "100%",
            marginTop: 30,
            marginBottom: 100,
            textAlign: "center",
            paddingLeft: isMobile ? 10 : 0,
            paddingRight: isMobile ? 10 : 0,
          }}
          justify="center"
        >
          {isLoading ? <Loading /> : <Col>{getIntakeFormComponent()}</Col>}
        </Row>
      </Col>
    </Row>
  );
};
