import { z } from "zod";

import { measurementSchema } from "../types";

export const createMeasurementEndpointRequestSchema = measurementSchema
  .omit({
    createdAt: true,
    updatedAt: true,
    deletedAt: true,
  })
  .extend({
    // For measurements, we are using client side generated UUIDs.
    id: z.string(),
    sessionId: z.string(),
  })
  .strict();

export type ICreateMeasurementEndpointRequest = z.input<
  typeof createMeasurementEndpointRequestSchema
>;

export const updateMeasurementEndpointRequestSchema = measurementSchema
  .omit({
    updatedAt: true,
    deletedAt: true,
  })
  .deepPartial()
  .extend({
    id: z.string(),
    sessionId: z.string(),
  })
  .strict();

export type IUpdateMeasurementEndpointRequest = z.input<
  typeof updateMeasurementEndpointRequestSchema
>;

export const createOrUpdateMeasurementEndpointRequestSchema = measurementSchema
  .omit({
    createdAt: true,
    updatedAt: true,
    deletedAt: true,
  })
  .extend({
    sessionId: z.string(),
  })
  .strict();

export type ICreateOrUpdateMeasurementEndpointRequest = z.input<
  typeof createOrUpdateMeasurementEndpointRequestSchema
>;
